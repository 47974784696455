import * as Constants from './Constants';

export const getBaseUrl = (): string =>
  `${window.location.protocol}//${window.location.host}${window?.DYDU_TARGETS?.on_atria ? '/dydubox' : ''}`;

export const removeProtocol = (urlString: any) => {
  const regexProtocol = new RegExp('^(http|https)://');
  if (urlString !== null) {
    return urlString?.replace(regexProtocol, '');
  }
  return null;
};

export const getOnPremCdnUrl = () => window.DYDU_TARGETS.on_atria && window.DYDU_TARGETS.cdn_url;

export const stringToHashCode = (s) => [...s].reduce((hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0, 0);

export const getIframePreviewBundleUrl = () => {
  // Here, ${new Date().getSeconds()} is required to reload CV5 iframe, to update preview
  let cdnUrl = `${window.DYDU_TARGETS.cdn_url}/${Constants.CHATBOX_VERSION}/loader.js?${new Date().getSeconds()}`;
  const pathArray = cdnUrl.split('/');
  const protocol = pathArray[0];
  const host = pathArray[2];

  const proxyUrl = sessionStorage.getItem(Constants.DyduStorage.PROXY);

  if (proxyUrl) {
    cdnUrl = cdnUrl.replace(`${protocol}//${host}`, proxyUrl);
    cdnUrl = cdnUrl.replace(Constants.CHATBOX_VERSION, `${Constants.CHATBOX_VERSION}_${stringToHashCode(proxyUrl)}`);
  }
  return cdnUrl;
};
